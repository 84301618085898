import styled from "styled-components";

export const List = styled.div`
    background-color: #F3F3F3;
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    margin: 20px 0;
`;

export const ListItem = styled.div`
    cursor: pointer;
    padding: 20px 0;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
        border-bottom: none;
    }
    & > p:nth-child(2) {
        color: #9B9B9B;
    }
`;