import styled, { css } from "styled-components";

export const Header = styled.h1`
    font-weight: 600;
    padding: 10px 0;
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

