import styled from "styled-components";

const Select = styled.select`
    font-size: 17px;
    line-height: 1.23536;
    font-family: "Inter";
    width: 100%;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const StyledDropdownInput = styled.div`
    font-size: 17px;
    line-height: 1.23536;
    font-family: "Inter";
    width: 100%;
    height: 60px;
    border-radius: 13px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px;
    text-align: left;
    color: #2c2c2c;
    border-color: #d2d2d7;
    background-color: hsla(0, 0%, 100%, .8);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DropdownInput = (props) => {
    return (
        <StyledDropdownInput>
            <Select value={props.value} onChange={props.onChange}>
                {props.children}
            </Select>
            <i className="fal fa-chevron-down"></i>
        </StyledDropdownInput>
    );
}