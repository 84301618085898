import { Link, Redirect, Route } from "wouter";
import Sidebar from "../Common/Sidebar";
import styled, { css } from "styled-components";
import "../../App.css";
import { Main } from "../Common/Main";
import { Fragment, useEffect, useState } from "react";
import { APP_ID, IDSERVICE_AUTHORIZATION_URL, IDSERVICE_WEBAPI_URL } from "../../api/constants";
import Auth from "../Common/Auth";
import Overview from "./Pages/Overview";
import Account from "./Pages/Account";
import RedirectToAuthorization from "../Common/RedirectToAuthorization";
import Events from "./Pages/Events";

const Wrapper = styled.main`

    height: 100%;
    width: 100%;
   
`;


const App = () => {

    const [authenticated, setAuthenticated] = useState("loading");

    useEffect(() => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/authorize", {
            method: "GET",
            credentials: "include",
        }).then(async res => {
            let json = await res.json();
            const sessionToken = json.sessionToken;
            if (json.authorized == true) {
                setAuthenticated(true)

            } else {
                setAuthenticated(false);
            }

        })

    }, [])

    
    if (authenticated == true) {
        return (

            <div>
                < Main >
                    <Sidebar />

                    <Wrapper>
                        <Route path="/">
                            <Redirect to="/overview" />
                        </Route>

                        <Route path="/overview" component={Overview} />

                        <Route path="/events" component={Events} />

                        <Route path="/account" component={Account} />
                    </Wrapper>
                </Main >
            </div >

        )
    }
    else if (!authenticated) {
        return (

            <Fragment>
                <Route path="/auth" component={Auth} />
                <Route path="/:rest" component={RedirectToAuthorization} />
            </Fragment>

        )
    }
    else {
        return ( <div></div> );
    }
}

export default App;