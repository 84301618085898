import { Link } from "wouter";
import styled from "styled-components";

export const PrimaryButton = styled.a`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;
    padding: 12px 20px;
    border-radius: 10px;
    background: #0874da;
    color: #fff;
`;

export const PrimaryButtonBtn = styled.button`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;
    padding: 12px 20px;
    border-radius: 10px;
    background: #0874da;
    color: #fff;
    border: none;
    outline: none;
    font-family: "Inter";

    &:disabled {
        cursor: auto;
        opacity: .32;
    }
`;

export const SecondaryButton = styled.a`

    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;
    padding: 12px 20px;
    border-radius: 10px;
    background: #F3F3F3;
    color: #000;

`;

export const SecondaryButtonBtn = styled.button`

    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;
    padding: 12px 20px;
    border-radius: 10px;
    background: #F3F3F3;
    color: #000;
    border: none;
    outline: none;
    font-family: "Inter";

    &:disabled {
        cursor: auto;
        opacity: .32;
    }

`;