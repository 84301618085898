import styled, { css } from "styled-components";
import { Header } from "../../../Common/Header";
import { StrongText } from "../../../Common/Text";
import { useState, useEffect } from "react";
import { METRICSPORTAL_WEBAPI_URL } from "../../../../api/constants";

const Wrapper = styled.div`
    background-color: #f8f8f8;
    height: 100vh;
    width: 100%;
    padding: 40px 100px;
    overflow: auto;
`;

const Metrics = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
`;

const Column = styled.div`
    display: grid;
    grid-gap: 30px;
`;

const Metric = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    height: ${({ height }) => (height == "tall" ? "350px" : "160px;")};

    &>h1 {
        margin-bottom: 5px;
    }

    &>h3 {
        color: #676767;
        font-weight: 600;
    }
`;

const Details = styled.div`
    margin-top: 20px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &>div>p, &>p {
        font-size: 14px;
    }
`;

const ConversionNumber = styled.p`
    color: #676767;
    font-size: 14px;
`;

const DetailsHeading = styled(StrongText)`
    margin-bottom: 10px;
`;

const Overview = () => {
    const [sales, setSales] = useState();
    const [topProducts, setTopProducts] = useState();
    const [orders, setOrders] = useState();
    const [averageOrderValue, setAverageOrderValue] = useState();
    const [impressions, setImpressions] = useState();
    const [sessions, setSessions] = useState();
    const [clicks, setClicks] = useState();
    const [mostClicked, setMostClicked] = useState();
    const [conversionRate, setConversionRate] = useState();
    const [productViewCount, setProductViewCount] = useState();
    const [productViewRate, setProductViewRate] = useState();
    const [addToBagCount, setAddToBagCount] = useState();
    const [addToBagRate, setAddToBagRate] = useState();
    const [checkoutCount, setCheckoutCount] = useState();
    const [checkoutRate, setCheckoutRate] = useState();
    const [paymentAddedCount, setPaymentAddedCount] = useState();
    const [paymentAddedRate, setPaymentAddedRate] = useState();

    const [lastDays, setLastDays] = useState(1);

    useEffect(() => {
       
            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/sales?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setSales(json.total);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/top-products?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setTopProducts(json);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/orders?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setOrders(json.orders);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/average-order-value?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setAverageOrderValue(json.averageOrderValue);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/impressions?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setImpressions(json.impressions);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/sessions?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setSessions(json.sessions);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/clicks?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setClicks(json.clicks);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/most-clicked?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setMostClicked(json);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/conversion-rate?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setConversionRate(json.conversionRate);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/product-view-rate?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setProductViewCount(json.conversions);
                setProductViewRate(json.conversionRate);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/add-to-bag-rate?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setAddToBagCount(json.conversions);
                setAddToBagRate(json.conversionRate);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/checkout-rate?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setCheckoutCount(json.conversions);
                setCheckoutRate(json.conversionRate);

            })

            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/payment-added-rate?lastDays=" + lastDays, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setPaymentAddedCount(json.conversions);
                setPaymentAddedRate(json.conversionRate);

            })

  
    }, [lastDays])

    const displayTopProducts = () => {
        return topProducts.map((p) => (
            <Row key={p.name}>
                <div>
                    <p>{p.name}</p>
                    <ConversionNumber>{p.magnitude.toLocaleString()} sales</ConversionNumber>
                </div>
                <p>{(p.magnitude / orders * 100).toFixed(2)}%</p>
            </Row>
        ))
    }

    const displayMostClicked = () => {
        return mostClicked.map((p) => (
            <Row key={p.object_id}>
                <div>
                    <p>{p.object_id}</p>
                    <ConversionNumber>{p.magnitude.toLocaleString()} clicks</ConversionNumber>
                </div>
                <p>{(p.magnitude / clicks * 100).toFixed(2)}%</p>
            </Row>
        ))
    }

    return (
        <Wrapper>
            <Header>

                <span>Overview</span>

                <select onChange={(e) => { setLastDays(e.target.value); console.log(e.target.value) }}>
                    <option value={1}>Today</option>
                    <option value={7}>7 days</option>
                    <option value={14}>14 days</option>
                    <option value={30}>30 days</option>
                    <option value={90}>3 months</option>
                    <option value={180}>6 months</option>
                    <option value={365}>1 year</option>
                    <option value={1000000}>Lifetime</option>
                </select>

            </Header>

            <Metrics>
                <Metric height="tall">
                    <h1>${sales && sales.toFixed(2).toLocaleString()}</h1>
                    <h3>Sales</h3>

                    <Details>
                        <DetailsHeading>Top Products</DetailsHeading>



                        {topProducts && displayTopProducts()}
                    </Details>
                </Metric>


                <Column>
                    <Metric>
                        <h1>{impressions && impressions.toLocaleString()}</h1>
                        <h3>Impressions</h3>
                    </Metric>

                    <Metric>
                        <h1>{sessions && sessions.toLocaleString()}</h1>
                        <h3>Sessions</h3>
                    </Metric>
                </Column>

                <Metric height="tall">
                    <h1>{clicks && clicks.toLocaleString()}</h1>
                    <h3>Clicks</h3>

                    <Details>
                        <DetailsHeading>Most clicked</DetailsHeading>

                        {mostClicked && displayMostClicked()}
                    </Details>
                </Metric>

                <Metric height="tall">
                    <h1>{orders && orders}</h1>
                    <h3>Orders</h3>
                </Metric>

                <Metric height="tall">
                    <h1>{conversionRate && (conversionRate * 100).toFixed(2)}%</h1>
                    <h3>Conversion rate</h3>

                    <Details>
                        <Row>
                            <div>
                                <p>Viewed product</p>
                                <ConversionNumber>{productViewCount && productViewCount.toLocaleString()} sessions</ConversionNumber>
                            </div>
                            <p>{productViewRate && (productViewRate * 100).toFixed(2)}%</p>
                        </Row>

                        <Row>
                            <div>
                                <p>Added to bag</p>
                                <ConversionNumber>{addToBagCount && addToBagCount.toLocaleString()} sessions</ConversionNumber>
                            </div>
                            <p>{addToBagRate && (addToBagRate * 100).toFixed(2)}%</p>
                        </Row>

                        <Row>
                            <div>
                                <p>Initiated checkout</p>
                                <ConversionNumber>{checkoutCount && checkoutCount} sessions</ConversionNumber>
                            </div>
                            <p>{checkoutRate && (checkoutRate * 100).toFixed(2)}%</p>
                        </Row>

                        <Row>
                            <div>
                                <p>Added payment method</p>
                                <ConversionNumber>{paymentAddedCount && paymentAddedCount} sessions</ConversionNumber>
                            </div>
                            <p>{paymentAddedRate && (paymentAddedRate * 100).toFixed(2)}%</p>
                        </Row>
                    </Details>
                </Metric>

                <Metric height="tall">
                    <h1>${averageOrderValue && averageOrderValue.toFixed(2).toLocaleString()}</h1>
                    <h3>Average order value</h3>
                </Metric>

            </Metrics>
        </Wrapper>
    )
}

export default Overview;