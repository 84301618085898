import styled, { css } from "styled-components";
import { Header } from "../../../Common/Header";
import { StrongText } from "../../../Common/Text";
import { useState, useEffect } from "react";
import { METRICSPORTAL_WEBAPI_URL } from "../../../../api/constants";
import { DropdownInput } from "../../../Common/Select";
import { PrimaryButton } from "../../../Common/Button";
import { LoadingSpinner } from "../../../Common/LoadingSpinner";

const Wrapper = styled.div`
    background-color: #f8f8f8;
    height: 100vh;
    width: 100%;
    padding: 40px 100px;
    overflow: auto;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const EventTypeWrapper = styled.div`
    width: 300px;
    margin-right: 20px;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const Event = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const EventTitle = styled.h3`
    margin-bottom: 5px;
`;

const Events = () => {

    const [event, setEvent] = useState("add-to-bag");
    const [events, setEvents] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/events/" + event, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setEvents(json);

        })
    }, [event])

    useEffect(() => {
        if (refresh === true) {
            setEvents();
            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/events/" + event, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setEvents(json);
                setRefresh(false);
            })
        }
    }, [refresh])

    const displayEvents = () => {
        var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        switch (event) {
            case "add-to-bag": {
                return events.map((e) => (
                    <Event key={e.id}>
                        <div>
                            <EventTitle>Add to Bag</EventTitle>
                            <p>{e.productName}</p>
                            <p>{e.cid}</p>
                        </div>
                        <div>
                            <p>{new Date(e.createdAt).toLocaleTimeString('en-US') + " " + new Date(e.createdAt).toLocaleDateString("en-US", dateOptions)}</p>
                        </div>
                    </Event>
                ))
            }
            case "checkout": {
                return events.map((e) => (
                    <Event key={e.id}>
                        <div>
                            <EventTitle>Checkout</EventTitle>
                            <p>{e.cid}</p>
                        </div>
                        <div>
                            <p>{new Date(e.createdAt).toLocaleTimeString('en-US') + " " + new Date(e.createdAt).toLocaleDateString("en-US", dateOptions)}</p>
                        </div>
                    </Event>
                ))
            }
            case "payment-added": {
                return events.map((e) => (
                    <Event key={e.id}>
                        <div>
                            <EventTitle>Payment Added</EventTitle>
                            <p>{e.cid}</p>
                        </div>
                        <div>
                            <p>{new Date(e.createdAt).toLocaleTimeString('en-US') + " " + new Date(e.createdAt).toLocaleDateString("en-US", dateOptions)}</p>
                        </div>
                    </Event>
                ))
            }
            case "product-view": {
                return events.map((e) => (
                    <Event key={e.id}>
                        <div>
                            <EventTitle>View Product</EventTitle>
                            <p>{e.productName}</p>
                            <p>{e.cid}</p>
                        </div>
                        <div>
                            <p>{new Date(e.createdAt).toLocaleTimeString('en-US') + " " + new Date(e.createdAt).toLocaleDateString("en-US", dateOptions)}</p>
                        </div>
                    </Event>
                ))
            }
            default: {
                return <p>Invalid event type.</p>;
            }
        }
    }

    return (
        <Wrapper>
            <Header>Events</Header>

            <Top>
                <EventTypeWrapper>
                    <DropdownInput value={event} onChange={(e) => { setEvent(e.target.value) }}>
                        <option value="add-to-bag">Add to Bag</option>
                        <option value="checkout">Initiate Checkout</option>
                        <option value="payment-added">Add Payment Method</option>
                        <option value="product-view">View product</option>
                    </DropdownInput>
                </EventTypeWrapper>

                <PrimaryButton onClick={() => setRefresh(true)}><i className="fa fa-arrow-rotate-left"></i></PrimaryButton>
            </Top>

            <div>
                {events ? displayEvents() : <LoadingContainer><LoadingSpinner /></LoadingContainer>}
            </div>

        </Wrapper>
    )
}

export default Events;