import { useLocation, Link } from "wouter";
import SidebarLogo from "../../../assets/images/caseful_logo.png";

import styled, { css } from "styled-components";

const StyledSidebar = styled.nav`
    background-color: #F3F3F3;
    flex: 25%;
    height: 100vh;
    z-index: 1000;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    padding: 20px 15px;
    border-right: 1px solid #DBDBDB;
`;

const SidebarBranding = styled.h2`
    font-weight: 600;
    padding: 0px 10px;
`

const SidebarLinkGroup = styled.div`
    margin: 40px 0px;
`

const SidebarLink = styled(Link)`
    padding: 10px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5px;

    display: flex;
    align-items: center;

    &:hover {
        background-color: #DBDBDB;
      }

    ${props => SidebarLinkActive[props.active]}
`

const SidebarLinkActive = {
    true: css`
        background-color: #DBDBDB;
        `
};

const SidebarLinkText = styled.p`
${props => SidebarLinkTextActive[props.active]}
`

const SidebarLinkTextActive = {
    true: css`
        font-weight: 600;
        `
};

const SidebarLinkIcon = styled.i`
      color: #0874DA;
      font-size: 16px;
      margin-right: 12px;
`

const SidebarFooter = styled.div`
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;

    & > p {
        font-size: 12px;
        color: #9B9B9B;
    }
`;

const Sidebar = () => {

    let [location, setLocation] = useLocation();

    return (
        <StyledSidebar>

            <SidebarBranding> <img src={SidebarLogo} height="18" /> <br /> Metrics</SidebarBranding>

            <SidebarLinkGroup>
                <SidebarLink active={location == "/overview" ? true : false} to="/overview">
                    <SidebarLinkIcon className="fa fa-list-ul" />
                    <SidebarLinkText active={location == "/overview" ? true : false}>Overview</SidebarLinkText>
                </SidebarLink>

                <SidebarLink active={location == "/events" ? true : false} to="/events">
                    <SidebarLinkIcon className="fa fa-flag" />
                    <SidebarLinkText active={location == "/events" ? true : false}>Events</SidebarLinkText>
                </SidebarLink>

            </SidebarLinkGroup>

            <SidebarLinkGroup>

                <SidebarLink active={location == "/account" ? true : false} to="/account">
                    <SidebarLinkIcon className="fa fa-user" />
                    <SidebarLinkText active={location == "/account" ? true : false}>Account</SidebarLinkText>
                </SidebarLink>
            </SidebarLinkGroup>

            <SidebarFooter>
                <p>Copyright &copy; 2022 Caseful. <br />
                    <a href="https://shopcaseful.com/legal/privacy-policy">Privacy Policy</a> &#903; <a>Report a problem</a></p>
            </SidebarFooter>


        </StyledSidebar>
    )
}

export default Sidebar;