import { Fragment, useEffect, useState } from "react";
import { APP_ID, IDSERVICE_SIGNOUT_URL, IDSERVICE_WEBAPI_URL } from "../../../../api/constants";
import { Header } from "../../../Common/Header";
import { List, ListItem } from "../../../Common/List";
import styled, { css } from "styled-components";

 const Wrapper = styled.div`
    height: 100vh;
    padding: 40px 200px;
`;

const Account = () => {

    const [accountId, setAccountId] = useState("")
    

    useEffect(() => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/profile", {
            method: "GET",
            credentials: "include",
            credentials: 'include'
        }).then(async res => {
            let json = await res.json();
            if (!json.error) {
                setAccountId(json.accountId);

            } else {
                console.log("error")
            }

        })

    }, [])

    const signOut = () => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/signout", {
            method: "POST",
            credentials: "include",
            credentials: 'include'
        }).then(async res => {
            let json = await res.json();
            if (json.code == 0) {
                window.location.href = IDSERVICE_SIGNOUT_URL + "?appId=" + APP_ID;

            } else {
                console.log("error")
            }

        })
    }


    return (
        <Wrapper>
            <Header>Account</Header>

            <List>
                <ListItem>
                    <p>Signed in as</p>
                    <p>{accountId} (via Caseful ID)</p>
                </ListItem>
            </List>

            <List>
                <ListItem>
                    <p>Report a problem</p>
                </ListItem>
                <ListItem>
                    <p>Privacy Policy</p>
                </ListItem>
            </List>

            <List>
                <ListItem onClick={() => signOut()}>
                    <p>Sign out</p>
                </ListItem>
            </List>
        </Wrapper>
    )
}

export default Account;