export const APP_ID = process.env.NODE_ENV === "development"
    ? "bad30b29-bcaa-11ec-bc43-029705c1f506" : "edae20a6-f2d2-11ec-bc43-029705c1f506";

export const METRICSPORTAL_WEBAPI_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3001" : "https://casefulwebapi.shopcaseful.com";

export const IDSERVICE_WEBAPI_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3001" : "https://casefulwebapi.shopcaseful.com";


export const IDSERVICE_AUTHORIZATION_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3002/auth/authorize" : "https://idservice.shopcaseful.com/auth/authorize";

export const IDSERVICE_SIGNOUT_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3002/auth/signout" : "https://idservice.shopcaseful.com/auth/signout";