import { useEffect } from "react";
import { APP_ID, IDSERVICE_AUTHORIZATION_URL } from "../../api/constants";

const RedirectToAuthorization = () => {
    useEffect(() => {
        window.location.href = IDSERVICE_AUTHORIZATION_URL + "?appId=" + APP_ID;
    }, [])
    return (<div></div>);
}

export default RedirectToAuthorization;